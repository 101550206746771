<template>
  <b-button
    :class="customClass"
    :variant="variant"
    v-on="$listeners"
    v-bind="$attrs"
    :squared="squared"
    :pill="pill"
  >
    {{ name }}
  </b-button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ""
    },
    variant: {
      type: String
    },
    customClass: {
      type: String
    },
    squared: {
      type: Boolean
    },
    pill: {
      type: Boolean
    }
  }
};
</script>
